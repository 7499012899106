/*
SPDX-FileCopyrightText: 2024 Genome Research Ltd.

SPDX-License-Identifier: MIT
*/
import { Widgets, Row } from "@tol/tol-ui";
import { Tabs } from 'rsuite';
import { isMobileOrTablet, randomGenerator, useStyle, getMidColor } from '../utils/Utils';

function Programme() {
    const { randomImage, startColor, endColor } = randomGenerator();
    const imageSource = require("../assets/" + randomImage);
    const midColor = getMidColor(startColor, endColor);
    useStyle(startColor, endColor, midColor);

    console.log(isMobileOrTablet());

    const programme = (
        <>
            <div className="information-container">
                <div className="custom-heading" style={{ display: "flex", justifyContent: "center", marginTop: "-15px", marginBottom: "-15px" }}>
                    <img src={imageSource} style={{ width: "100%", maxWidth: "300px" }} alt="bga24-banner-logo" />
                </div>
                <p className="custom-heading">Programme</p>
                <p>
                    BG24 will run from 28th October until 1st November 2024.
                </p>
                <p>
                    It features a mix of keynotes, global sessions on BioDiversity Genomics and Genomics Technologies,
                    sessions on specific topics proposed by researchers, meetings organised by EarthBiogenome Projects (EBP),
                    and presentations by our sponsors. In addition, look forward to an amazing musical performance!
                </p>
                <p style={{ fontWeight: "bold" }}>
                    This is a preliminary programme, timings and events are subject to change. Please check back regularly for updates.
                </p>
                <p>
                    The three days at the centre of BG24 are dedicated to regional and specific interest sessions
                    and each accommodate a different set of global timezones.
                </p>
                <table>
                    <thead>
                        <tr>
                            <th style={{ textAlign: "left" }}>Day</th>
                            <th>Date</th>
                            <th>Timezone</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Day 2</td>
                            <td>29th October</td>
                            <td>Daytime in the Americas.</td>
                        </tr>
                        <tr>
                            <td>Day 3</td>
                            <td>30th October</td>
                            <td>Daytime in Africa and Europe</td>
                        </tr>
                        <tr>
                            <td>Day 4</td>
                            <td>31st October</td>
                            <td>Daytime in Australasia and Asia</td>
                        </tr>
                    </tbody>
                </table>
                <p style={{ paddingTop: "20px" }}>In addition, several major EBP projects will meet on Zoom on</p>
                <p><span style={{ fontWeight: "bold" }}>Day 1, 28th October</span></p>
                <p><span style={{ fontWeight: "bold" }}>Day 4, 31st October</span> and</p>
                <p><span style={{ fontWeight: "bold" }}>Day 5, 1st November</span></p>
                <p>Everyone is invited!</p>
                <p className="programme-seperator">
                    The full programme can be found below, alternatively if you wish to download the programme, you can do so
                    <a href="https://docs.google.com/spreadsheets/d/1x3OItwOMF035akCLkdOJdVx48FBus80yFSMYSEmdv0k/edit?gid=1657860058#gid=1657860058" target="_blank" rel="noreferrer"> here
                    </a>
                    .
                </p>
                <p className="mobile-color-code-help">Colour key can be found below the programme.</p>
                <Row>
                    <div className="col-md-10">
                        <Tabs defaultActiveKey="1" vertical={isMobileOrTablet()}>
                            <Tabs.Tab eventKey="1" title="Monday 28th October">
                                <div className="information-container programme-timetable">
                                    <table className="timetable">
                                        <thead>
                                            <tr>
                                                <th className="vertical-text" style={{ textAlign: "left" }}>UTC+1 (CET)</th>
                                                <th>UTC (UK)</th>
                                                <th>UTC-4 (EST)</th>
                                                <th>UTC-7 (PST)</th>
                                                <th>UTC+11 (EAUS)</th>
                                                <th>Key</th>
                                                <th style={{ paddingTop: "96px", paddingBottom: "0px" }}>EPB Project Day</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>13:00</td>
                                                <td>12:00</td>
                                                <td>8:00</td>
                                                <td>5:00</td>
                                                <td>23:00</td>
                                                <td><div className="color-code-key color-code-e table-key"></div></td>
                                                <td>
                                                    <a href="https://docs.google.com/document/d/10vOtD0bhrdeCdEcaTLU321t_d2335kAZC06D25S9V80/edit" target="_blank" rel="noreferrer">
                                                        The European Reference Genome Atlas Community / zoom
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>14:00</td>
                                                <td>13:00</td>
                                                <td>9:00</td>
                                                <td>6:00</td>
                                                <td>0:00</td>
                                                <td><div className="color-code-key color-code-e table-key"></div></td>
                                                <td>
                                                    <a href="https://docs.google.com/document/d/10vOtD0bhrdeCdEcaTLU321t_d2335kAZC06D25S9V80/edit" target="_blank" rel="noreferrer">
                                                        iBOL Europe: A regional nexus for the DNA barcoding community / Zoom
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>15:00</td>
                                                <td>14:00</td>
                                                <td>10:00</td>
                                                <td>7:00</td>
                                                <td>1:00</td>
                                                <td><div className="color-code-key color-code-e table-key"></div></td>
                                                <td>
                                                    <a href="https://docs.google.com/document/d/10vOtD0bhrdeCdEcaTLU321t_d2335kAZC06D25S9V80/edit" target="_blank" rel="noreferrer">
                                                        BGE: Biodiversity Genomics Europe: Scaling up capacity production, and application / Zoom
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>16:00</td>
                                                <td>15:00</td>
                                                <td>11:00</td>
                                                <td>8:00</td>
                                                <td>2:00</td>
                                                <td><div className="color-code-key color-code-e table-key"></div></td>
                                                <td>
                                                    <a href="https://docs.google.com/document/d/1j5H78Qmj3-Yfsqx4mmLAIKUv3Qfg3lyJIfleHhJDSiM/edit" target="_blank" rel="noreferrer">
                                                        Aquatic Symbiosis Genomics / Zoom
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>17:00</td>
                                                <td>16:00</td>
                                                <td>12:00</td>
                                                <td>9:00</td>
                                                <td>3:00</td>
                                                <td><div className="color-code-key color-code-e table-key"></div></td>
                                                <td>
                                                    <a href="https://docs.google.com/document/d/1Fjf6gPoVogm7ZISvfGhxzlvryi0DyKnBCe_Mi6UDaxo/edit" target="_blank" rel="noreferrer">
                                                        "Beenomes and Badnomes: Advances in applied insect genomics (i5K) / Zoom"
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>18:00</td>
                                                <td>17:00</td>
                                                <td>13:00</td>
                                                <td>10:00</td>
                                                <td>4:00</td>
                                                <td><div className="color-code-key color-code-e table-key"></div></td>
                                                <td>
                                                    <a href="https://docs.google.com/document/d/1M_0QSL07ip6vVP6jVrlwcaIlpUa8wbydp-A4dZNs__o/edit" target="_blank" rel="noreferrer">
                                                        Exploring the evolution and genomic basis of beetle (Coleoptera) diversity (i5K) / Zoom
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>19:00</td>
                                                <td>18:00</td>
                                                <td>14:00</td>
                                                <td>11:00</td>
                                                <td>5:00</td>
                                                <td><div className="color-code-key color-code-e table-key"></div></td>
                                                <td>
                                                    <a href="https://docs.google.com/document/d/17dosvRZT0KnyCh-u3zhxY_YPqLETuQZ7FdX0f2nUpA8/edit" target="_blank" rel="noreferrer">
                                                        i5K community meeting / Zoom
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Tabs.Tab>
                            <Tabs.Tab eventKey="2" title="Tuesday 29th October">
                                <div className="information-container programme-timetable">
                                    <table className="timetable">
                                        <thead>
                                            <tr>
                                                <th className="vertical-text" style={{ textAlign: "left" }}>UTC+1 (CET)</th>
                                                <th>UTC (UK)</th>
                                                <th>UTC-4 (EST)</th>
                                                <th>UTC-7 (PST)</th>
                                                <th>UTC+11 (EAUS)</th>
                                                <th>Key</th>
                                                <th style={{ paddingTop: "96px", paddingBottom: "0px" }}>Europe, South and North Americas Day</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>14:00</td>
                                                <td>13:00</td>
                                                <td>9:00</td>
                                                <td>6:00</td>
                                                <td>0:00</td>
                                                <td><div className="color-code-key color-code-f table-key"></div></td>
                                                <td>
                                                    <a href="https://docs.google.com/document/d/17-hvcivU-aBNxseKpqO5uPWdY8Ml8hkKWoqBn6_-bAc/edit" target="_blank" rel="noreferrer">
                                                        Welcome
                                                    </a>
                                                </td>

                                            </tr>
                                            <tr>
                                                <td>15:00</td>
                                                <td>14:00</td>
                                                <td>10:00</td>
                                                <td>7:00</td>
                                                <td>1:00</td>
                                                <td><div className="color-code-key color-code-b table-key"></div></td>
                                                <td>
                                                    <a href="https://docs.google.com/document/d/1V-15Y0s2fsMXIFsJ5sTcmBVkjyUcqwKWq3BE8VyHaiM/edit" target="_blank" rel="noreferrer">
                                                        BioDivGenomics Europe I
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>16:00</td>
                                                <td>15:00</td>
                                                <td>11:00</td>
                                                <td>8:00</td>
                                                <td>2:00</td>
                                                <td><div className="color-code-key color-code-b table-key"></div></td>
                                                <td>
                                                    <a href="https://docs.google.com/document/d/1V-15Y0s2fsMXIFsJ5sTcmBVkjyUcqwKWq3BE8VyHaiM/edit" target="_blank" rel="noreferrer">
                                                        BioDivGenomics Europe II
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>17:00</td>
                                                <td>16:00</td>
                                                <td>12:00</td>
                                                <td>9:00</td>
                                                <td>3:00</td>
                                                <td><div className="color-code-key color-code-c table-key"></div></td>
                                                <td>
                                                    <a href="https://docs.google.com/document/d/1pA5Jrgh_Wz3CLKXF4ZZcJWnGxi--4jwpsprMbYP4ik4/edit#heading=h.iq7yfhj364e5" target="_blank" rel="noreferrer">
                                                        Keynotes
                                                    </a>
                                                </td>

                                            </tr>
                                            <tr>
                                                <td>18:00</td>
                                                <td>19:00</td>
                                                <td>13:00</td>
                                                <td>10:00</td>
                                                <td>4:00</td>
                                                <td><div className="color-code-key color-code-d table-key"></div></td>
                                                <td>Sponsor Presentation</td>
                                            </tr>
                                            <tr>
                                                <td>19:00</td>
                                                <td>18:00</td>
                                                <td>14:00</td>
                                                <td>11:00</td>
                                                <td>5:00</td>
                                                <td><div className="color-code-key color-code-a table-key"></div></td>
                                                <td>
                                                    <a href="https://docs.google.com/document/d/15hFliq7I7RZeCplNJEjJ__uhEiQ44nEtjSFwAHOqXy4/edit" target="_blank" rel="noreferrer">
                                                        Genomics in the Neotropics
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>20:00</td>
                                                <td>19:00</td>
                                                <td>15:00</td>
                                                <td>12:00</td>
                                                <td>6:00</td>
                                                <td><div className="color-code-key color-code-b table-key"></div></td>
                                                <td>
                                                    <a href="https://docs.google.com/document/d/1nCZ9Mcktnw_cXZFmeGgLWcbM8WmvrFjuw2gDFeq4Uf8/edit" target="_blank" rel="noreferrer">
                                                        Genomics Technologies I
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>21:00</td>
                                                <td>20:00</td>
                                                <td>16:00</td>
                                                <td>13:00</td>
                                                <td>7:00</td>
                                                <td><div className="color-code-key color-code-b table-key"></div></td>
                                                <td>
                                                    <a href="https://docs.google.com/document/d/1nCZ9Mcktnw_cXZFmeGgLWcbM8WmvrFjuw2gDFeq4Uf8/edit" target="_blank" rel="noreferrer">
                                                        Genomics Technologies II
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>22:00</td>
                                                <td>21:00</td>
                                                <td>17:00</td>
                                                <td>14:00</td>
                                                <td>8:00</td>
                                                <td><div className="color-code-key color-code-b table-key"></div></td>
                                                <td>
                                                    <a href="https://docs.google.com/document/d/1V-15Y0s2fsMXIFsJ5sTcmBVkjyUcqwKWq3BE8VyHaiM/edit" target="_blank" rel="noreferrer">
                                                        BioDivGenomics Americas I
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>23:00</td>
                                                <td>22:00</td>
                                                <td>18:00</td>
                                                <td>15:00</td>
                                                <td>9:00</td>
                                                <td><div className="color-code-key color-code-b table-key"></div></td>
                                                <td>
                                                    <a href="https://docs.google.com/document/d/1V-15Y0s2fsMXIFsJ5sTcmBVkjyUcqwKWq3BE8VyHaiM/edit" target="_blank" rel="noreferrer">
                                                        BioDivGenomics Americas II
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Tabs.Tab>
                            <Tabs.Tab eventKey="3" title="Wednesday 30th October">
                                <div className="information-container programme-timetable">
                                    <table className="timetable">
                                        <thead>
                                            <tr>
                                                <th className="vertical-text" style={{ textAlign: "left" }}>UTC+1 (CET)</th>
                                                <th>UTC (UK)</th>
                                                <th>UTC-4 (EST)</th>
                                                <th>UTC-7 (PST)</th>
                                                <th>UTC+11 (EAUS)</th>
                                                <th>Key</th>
                                                <th style={{ paddingTop: "96px", paddingBottom: "0px" }}>Africa and Europe Day</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>9:00</td>
                                                <td>8:00</td>
                                                <td>4:00</td>
                                                <td>1:00</td>
                                                <td>19:00</td>
                                                <td><div className="color-code-key color-code-b table-key"></div></td>
                                                <td>
                                                    <a href="https://docs.google.com/document/d/1V-15Y0s2fsMXIFsJ5sTcmBVkjyUcqwKWq3BE8VyHaiM/edit" target="_blank" rel="noreferrer">
                                                        BioDivGenomics Africas I
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>10:00</td>
                                                <td>9:00</td>
                                                <td>5:00</td>
                                                <td>2:00</td>
                                                <td>20:00</td>
                                                <td><div className="color-code-key color-code-b table-key"></div></td>
                                                <td>
                                                    <a href="https://docs.google.com/document/d/1V-15Y0s2fsMXIFsJ5sTcmBVkjyUcqwKWq3BE8VyHaiM/edit" target="_blank" rel="noreferrer">
                                                        BioDivGenomics Africas II
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>11:00</td>
                                                <td>10:00</td>
                                                <td>6:00</td>
                                                <td>3:00</td>
                                                <td>21:00</td>
                                                <td><div className="color-code-key color-code-f table-key"></div></td>
                                                <td>Musical/Social</td>
                                            </tr>
                                            <tr>
                                                <td>12:00</td>
                                                <td>11:00</td>
                                                <td>7:00</td>
                                                <td>4:00</td>
                                                <td>22:00</td>
                                                <td><div className="color-code-key color-code-d table-key"></div></td>
                                                <td>Sponsor Presentation</td>
                                            </tr>
                                            <tr>
                                                <td>13:00</td>
                                                <td>12:00</td>
                                                <td>8:00</td>
                                                <td>5:00</td>
                                                <td>23:00</td>
                                                <td><div className="color-code-key color-code-a table-key"></div></td>
                                                <td>
                                                    <a href="https://docs.google.com/document/d/1z4frs1_vubfWpHx8Jzc4j6V5ZrBxj-3xpogC3YPYSGk/edit" target="_blank" rel="noreferrer">
                                                        Protists reference genomes: gaps, challenges and opportunities
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>14:00</td>
                                                <td>13:00</td>
                                                <td>9:00</td>
                                                <td>6:00</td>
                                                <td>0:00</td>
                                                <td><div className="color-code-key color-code-a table-key"></div></td>
                                                <td>
                                                    <a href="https://docs.google.com/document/d/1VA-sOiYXHH8gYym12En9AgWuPBwGfjiFCtSQyF6Csdg/edit" target="_blank" rel="noreferrer">
                                                        Genome After-Party, a repository of ready-made genome analysis
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>15:00</td>
                                                <td>14:00</td>
                                                <td>10:00</td>
                                                <td>7:00</td>
                                                <td>1:00</td>
                                                <td><div className="color-code-key color-code-a table-key"></div></td>
                                                <td>
                                                    <a href="https://docs.google.com/document/d/1RML_iol1wv1xSaaThNLK7uYXkKRhMxFQPzvyaJ2F3gs/edit?usp=sharing" target="_blank" rel="noreferrer">
                                                        Tree of Sex
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>16:00</td>
                                                <td>15:00</td>
                                                <td>11:00</td>
                                                <td>8:00</td>
                                                <td>2:00</td>
                                                <td><div className="color-code-key color-code-a table-key"></div></td>
                                                <td>
                                                    <a href="https://docs.google.com/document/d/1cbg1RLc80jE2fb8jEIoPH56kIZ2znsbYWmbKRsZGk3o/edit" target="_blank" rel="noreferrer">
                                                        Transposon evolution in the era of Biodiversity Genomics
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>17:00</td>
                                                <td>16:00</td>
                                                <td>12:00</td>
                                                <td>9:00</td>
                                                <td>3:00</td>
                                                <td><div className="color-code-key color-code-f table-key"></div></td>
                                                <td>Musical/Social</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div >
                            </Tabs.Tab>
                            <Tabs.Tab eventKey="4" title="Thursday 31st October">
                                <div className="information-container programme-timetable">
                                    <table className="timetable">
                                        <thead>
                                            <tr>
                                                <th className="vertical-text" style={{ textAlign: "left" }}>UTC+1 (CET)</th>
                                                <th>UTC (UK)</th>
                                                <th>UTC-4 (EST)</th>
                                                <th>UTC-7 (PST)</th>
                                                <th>UTC+11 (EAUS)</th>
                                                <th>Key</th>
                                                <th style={{ paddingTop: "96px", paddingBottom: "0px" }}>Asia and Australasia Day</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1:00</td>
                                                <td>0:00</td>
                                                <td>20:00</td>
                                                <td>17:00</td>
                                                <td>11:00</td>
                                                <td><div className="color-code-key color-code-b table-key"></div></td>
                                                <td>
                                                    <a href="https://docs.google.com/document/d/1V-15Y0s2fsMXIFsJ5sTcmBVkjyUcqwKWq3BE8VyHaiM/edit" target="_blank" rel="noreferrer">
                                                        BioDivGenomics Australasia I
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>2:00</td>
                                                <td>1:00</td>
                                                <td>21:00</td>
                                                <td>18:00</td>
                                                <td>12:00</td>
                                                <td><div className="color-code-key color-code-b table-key"></div></td>
                                                <td>
                                                    <a href="https://docs.google.com/document/d/1V-15Y0s2fsMXIFsJ5sTcmBVkjyUcqwKWq3BE8VyHaiM/edit" target="_blank" rel="noreferrer">
                                                        BioDivGenomics Australasia II
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>3:00</td>
                                                <td>2:00</td>
                                                <td>22:00</td>
                                                <td>19:00</td>
                                                <td>12:00</td>
                                                <td><div className="color-code-key color-code-d table-key"></div></td>
                                                <td>Sponsor Presentation</td>
                                            </tr>
                                            <tr>
                                                <td>4:00</td>
                                                <td>3:00</td>
                                                <td>23:00</td>
                                                <td>20:00</td>
                                                <td>13:00</td>
                                                <td><div className="color-code-key color-code-a table-key"></div></td>
                                                <td>
                                                    <a href="https://docs.google.com/document/d/1VA2gm_sWjknaEJBTeaQi_hHUWGZ_vqicQnnHKb94nl8/edit" target="_blank" rel="noreferrer">
                                                        Research of ECRs who work in biodiversity genomics
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>5:00</td>
                                                <td>4:00</td>
                                                <td>0:00</td>
                                                <td>21:00</td>
                                                <td>14:00</td>
                                                <td><div className="color-code-key color-code-a table-key"></div></td>
                                                <td>
                                                    <a href="https://docs.google.com/document/d/1kIGXaZip9EduLPRhSIQ3AV17v52OMiEq81wEvcVnl-8/edit" target="_blank" rel="noreferrer">
                                                        Marine vertebrate genomics
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>6:00</td>
                                                <td>5:00</td>
                                                <td>1:00</td>
                                                <td>22:00</td>
                                                <td>15:00</td>
                                                <td><div className="color-code-key color-code-a table-key"></div></td>
                                                <td>
                                                    <a href="https://docs.google.com/document/d/1bnT8VnOynNdShmki-wB_yHhfT40q6BqN0JsV1pRjsvE/edit" target="_blank" rel="noreferrer">
                                                        Beyond the reference: genomics for amphibian research and conservation
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>7:00</td>
                                                <td>6:00</td>
                                                <td>2:00</td>
                                                <td>23:00</td>
                                                <td>16:00</td>
                                                <td><div className="color-code-key color-code-a table-key"></div></td>
                                                <td>
                                                    <a href="https://docs.google.com/document/d/1caRxup-n5InEj9elk5avz-rR-2cALDgrx2qlEO8so_U/edit" target="_blank" rel="noreferrer">
                                                        Tools for eDNA and metagenomics
                                                    </a>
                                                </td>
                                            </tr>
                                            <div style={{ height: "25px" }}></div>
                                            <tr>
                                                <td>15:00</td>
                                                <td>14:00</td>
                                                <td>10:00</td>
                                                <td>7:00</td>
                                                <td>1:00</td>
                                                <td><div className="color-code-key color-code-e table-key"></div></td>
                                                <td>
                                                    <a href="https://docs.google.com/document/d/195mxvg8vt0sIUtC6B8WkxHIF9KaD9XNR2eV-izUcK5M/edit" target="_blank" rel="noreferrer">
                                                        Vertebrate Genomes Project
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>16:00</td>
                                                <td>15:00</td>
                                                <td>11:00</td>
                                                <td>8:00</td>
                                                <td>2:00</td>
                                                <td><div className="color-code-key color-code-e table-key"></div></td>
                                                <td>
                                                    <a href="https://docs.google.com/document/d/195mxvg8vt0sIUtC6B8WkxHIF9KaD9XNR2eV-izUcK5M/edit" target="_blank" rel="noreferrer">
                                                        Vertebrate Genomes Project
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>17:00</td>
                                                <td>16:00</td>
                                                <td>12:00</td>
                                                <td>9:00</td>
                                                <td>3:00</td>
                                                <td><div className="color-code-key color-code-e table-key"></div></td>
                                                <td>
                                                    <a href="https://docs.google.com/document/d/195mxvg8vt0sIUtC6B8WkxHIF9KaD9XNR2eV-izUcK5M/edit" target="_blank" rel="noreferrer">
                                                        Vertebrate Genomes Project
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>18:00</td>
                                                <td>17:00</td>
                                                <td>13:00</td>
                                                <td>10:00</td>
                                                <td>4:00</td>
                                                <td><div className="color-code-key color-code-e table-key"></div></td>
                                                <td>
                                                    <a href="https://docs.google.com/document/d/1SpTzqgHIdKHPbOWd0oSp-aX6YLaDPdSTSkXE0iDVrfg/edit" target="_blank" rel="noreferrer">
                                                        EBP JEDI committee: A fireside chat exploring social justice in biodiversity genomics / zoom
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>19:00</td>
                                                <td>18:00</td>
                                                <td>14:00</td>
                                                <td>11:00</td>
                                                <td>5:00</td>
                                                <td><div className="color-code-key color-code-e table-key"></div></td>
                                                <td>
                                                    <a href="https://docs.google.com/document/d/1unXCI3oFhbtKcBtCF-14gmraIWCdgnbpoTB4CTNwn8c/edit" target="_blank" rel="noreferrer">
                                                        California Conservation Genomics Project / Zoom
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Tabs.Tab>
                            <Tabs.Tab eventKey="5" title="Friday 1st November">
                                <div className="information-container programme-timetable">
                                    <table className="timetable">
                                        <thead>
                                            <tr>
                                                <th className="vertical-text" style={{ textAlign: "left" }}>UTC+1 (CET)</th>
                                                <th>UTC (UK)</th>
                                                <th>UTC-4 (EST)</th>
                                                <th>UTC-7 (PST)</th>
                                                <th>UTC+11 (EAUS)</th>
                                                <th>Key</th>
                                                <th style={{ paddingTop: "96px", paddingBottom: "0px" }}>EPB Project Day</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>14:00</td>
                                                <td>13:00</td>
                                                <td>9:00</td>
                                                <td>6:00</td>
                                                <td>0:00</td>
                                                <td><div className="color-code-key color-code-e table-key"></div></td>
                                                <td>
                                                    <a href="https://docs.google.com/document/d/1As8Bd8l0ZWivVwx146d24Bg1b5_imW67LrtbK1x0viw/edit" target="_blank" rel="noreferrer">
                                                        EBP Ethics Committee / Zoom
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>16:00</td>
                                                <td>15:00</td>
                                                <td>11:00</td>
                                                <td>8:00</td>
                                                <td>2:00</td>
                                                <td><div className="color-code-key color-code-e table-key"></div></td>
                                                <td>
                                                    <a href="https://docs.google.com/document/d/1YOimYE3wdMX8o_BCv-fI9v36dobdm4EOwdqnDN4RE3M/edit?usp=sharing" target="_blank" rel="noreferrer">
                                                        Indigenous Data Sovereignty in Biodiversity Genomics
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>15:00</td>
                                                <td>14:00</td>
                                                <td>10:00</td>
                                                <td>7:00</td>
                                                <td>1:00</td>
                                                <td><div className="color-code-key color-code-e table-key"></div></td>
                                                <td>
                                                    <a href="https://docs.google.com/document/d/1j81cjDE4pra2mM0TF4ykJT_E-mWIK7ebxos8IUjhTY4/edit?usp=sharing" target="_blank" rel="noreferrer">
                                                        Ruminant T2T Consortium / Zoom
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>17:00</td>
                                                <td>16:00</td>
                                                <td>12:00</td>
                                                <td>9:00</td>
                                                <td>3:00</td>
                                                <td><div className="color-code-key color-code-e table-key"></div></td>
                                                <td>
                                                    <a href="https://docs.google.com/document/d/1JbB4SJMbT1tEGO1H-YsI30e5SOiLOhsntI39hxZWuKs/edit" target="_blank" rel="noreferrer">
                                                        EBP business meeting / Zoom
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>18:00</td>
                                                <td>17:00</td>
                                                <td>13:00</td>
                                                <td>10:00</td>
                                                <td>4:00</td>
                                                <td><div className="color-code-key color-code-e table-key"></div></td>
                                                <td>
                                                    <a href="https://docs.google.com/document/d/1JbB4SJMbT1tEGO1H-YsI30e5SOiLOhsntI39hxZWuKs/edit" target="_blank" rel="noreferrer">
                                                        EBP business meeting / Zoom
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Tabs.Tab>
                        </Tabs>
                    </div>
                    <div className="col-md-2 color-code-key-container-wrapper">
                        <div className="color-code-container">
                            <p className="color-code-key-title">Colour key:</p>
                            <div className="color-code-key-container">
                                <div className="color-code-key color-code-a"></div>
                                <p className="color-code-key-label">Suggested Symposia</p>
                            </div>
                            <div className="color-code-key-container">
                                <div className="color-code-key color-code-b"></div>
                                <p className="color-code-key-label">General Sessions</p>
                            </div>
                            <div className="color-code-key-container">
                                <div className="color-code-key color-code-c"></div>
                                <p className="color-code-key-label">Keynotes</p>
                            </div>
                            <div className="color-code-key-container">
                                <div className="color-code-key color-code-d"></div>
                                <p className="color-code-key-label">Sponsor Presentation</p>
                            </div>
                            <div className="color-code-key-container">
                                <div className="color-code-key color-code-e"></div>
                                <p className="color-code-key-label">Zoom Only</p>
                            </div>
                            <div className="color-code-key-container">
                                <div className="color-code-key color-code-f"></div>
                                <p className="color-code-key-label">Other</p>
                            </div>
                        </div>
                    </div>
                </Row>
            </div>
        </>
    )

    const components = [
        {
            component: programme,
            type: "full"
        },
    ]

    return (
        <Widgets components={components} />
    )
};


export default Programme;