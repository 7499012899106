/*
SPDX-FileCopyrightText: 2024 Genome Research Ltd.

SPDX-License-Identifier: MIT
*/

import ReactDOM from 'react-dom';
import {
  Home,
  GeneralInfo,
  Programme,
  RegistrationAndAbstracts,
  BGA24,
  CodeOfConduct,
  PrivacyPolicy,
  TermsAndConditions,
  Sponsorships
} from './pages';
import reportWebVitals from './reportWebVitals';
import { TolApp, Page, Dropdown } from '@tol/tol-ui';
import Logo from './assets/logo.png'
import './scss/styling.scss';

const generalInfo: Page = {
  name: "General Information",
  element: <GeneralInfo />,
}

const programme: Page = {
  name: "Programme",
  element: <Programme />,
}

const registration: Page = {
  name: "Registration and Abstracts",
  element: <RegistrationAndAbstracts />,
}

const sponsorships: Page = {
  name: "Sponsorships",
  element: <Sponsorships />,
}

const bga24: Page = {
  name: "BGA24",
  element: <BGA24 />,
}

const codeOfConduct: Page = {
  name: "Code of Conduct",
  element: <CodeOfConduct />,
  hidden: true
}

const privacyPolicy: Page = {
  name: "Privacy Policy",
  element: <PrivacyPolicy />,
  hidden: true
}

const termsAndConditions: Page = {
  name: "Terms and Conditions",
  element: <TermsAndConditions />,
  hidden: true
}

const legalDropdown: Dropdown = {
  name: "Legal Information",
  pages: [
    codeOfConduct,
    privacyPolicy,
    termsAndConditions
  ]
}

ReactDOM.render(
  <TolApp
    brand={
      <img
        src={Logo}
        alt="Conference logo"
        style={{ height: 30 }}
      />
    }
    homePage={<Home />}
    pages={[
      programme,
      registration,
      generalInfo,
      sponsorships,
      bga24,
      legalDropdown,
      codeOfConduct,
      privacyPolicy,
      termsAndConditions
    ]}
    login={false}
  />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
